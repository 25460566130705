// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-xs {
    font-size: 12px;
    /* Ajuste conforme necessário */
}

.icon-sm {
    font-size: 16px;
    /* Ajuste conforme necessário */
}

.icon-md {
    font-size: 24px;
    /* Ajuste conforme necessário */
}

.icon-lg {
    font-size: 32px;
    /* Ajuste conforme necessário */
}

.icon-xl {
    font-size: 48px;
    /* Ajuste conforme necessário */
}`, "",{"version":3,"sources":["webpack://./src/components/UserAvatar/UserAvatar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC","sourcesContent":[".avatar {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.icon-xs {\r\n    font-size: 12px;\r\n    /* Ajuste conforme necessário */\r\n}\r\n\r\n.icon-sm {\r\n    font-size: 16px;\r\n    /* Ajuste conforme necessário */\r\n}\r\n\r\n.icon-md {\r\n    font-size: 24px;\r\n    /* Ajuste conforme necessário */\r\n}\r\n\r\n.icon-lg {\r\n    font-size: 32px;\r\n    /* Ajuste conforme necessário */\r\n}\r\n\r\n.icon-xl {\r\n    font-size: 48px;\r\n    /* Ajuste conforme necessário */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
