// src/pages/Signin2.js

import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import AuthContext from "../contexts/AuthContext"; // Ajuste o caminho conforme necessário
import bg1 from "../assets/img/bg1.jpg";

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isAuthenticated, login, checkToken } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      console.log(isAuthenticated)
      console.log('AUTENTICADO');
      navigate('/');
    } else if (isAuthenticated === null || isAuthenticated === false) {
      console.log(isAuthenticated)
      console.log('NAO AUTENTICADO');
      checkToken();
    }
  }, [isAuthenticated, navigate, checkToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Attempting login with email:', email);
    const success = await login(email, password);
    if (success) {
      console.log('Login successful, navigating to reports');
      navigate("/report");
    } else {
      console.log('Login failed');
      setError('Login failed. Please check your email and password.');
    }
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Link to="/" className="header-logo mb-5">dashbyte</Link>
              <Card.Title>LogIn</Card.Title>
              <Card.Text>Welcome back! Please sign in to continue.</Card.Text>
            </Card.Header>
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>} {/* Mostra mensagem de erro se houver */}
              <Form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Form.Label>Usuário</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Senha <Link to="">Esqueceu sua senha?</Link>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <Button type="submit" className="btn-sign">Login</Button>

                {/* <div className="divider"><span>or sign in with</span></div>

                <Row className="gx-2">
                  <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
                  <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
                </Row> */}
              </Form>
            </Card.Body>
            {/* <Card.Footer>
              Don't have an account? <Link to="/register">Create an Account</Link>
            </Card.Footer> */}
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  );
}
