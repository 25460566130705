import React from "react";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import RoutesGate from './contexts/RoutesGate';
import { AuthProvider } from './contexts/AuthContext';

import "./assets/css/remixicon.css";
import "./scss/style.scss";

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Redireciona da raiz para /report */}
          <Route path="/" element={<Navigate to="/report" />} />

          {/* Rotas protegidas com layout Main */}
          <Route path="/*" element={<RoutesGate><Main /></RoutesGate>}>
            {protectedRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.element}
              />
            ))}
            {/* Rota para NotFound dentro das rotas protegidas */}
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Rotas públicas */}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}

          {/* Fallback global para rotas não encontradas */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};



// export default function App() {
//   return (
//     <React.Fragment>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Main />}>
//             {protectedRoutes.map((route, index) => {
//               return (
//                 <Route
//                   path={route.path}
//                   element={route.element}
//                   key={index}
//                 />
//               )
//             })}
//           </Route>
//           {publicRoutes.map((route, index) => {
//             return (
//               <Route
//                 path={route.path}
//                 element={route.element}
//                 key={index}
//               />
//             )
//           })}
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       </BrowserRouter>
//     </React.Fragment>
    
//   );
// }