import React from 'react';
import { Card } from 'react-bootstrap';

const ResponseDisplay = ({ response }) => {
  return (
    <Card>
      <Card.Body>
        <h5>Resposta da API</h5>
        <pre>{response ? JSON.stringify(response, null, 2) : 'Nenhuma resposta recebida.'}</pre> {/* Formata o JSON */}
      </Card.Body>
    </Card>
  );
};

export default ResponseDisplay;
