// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DateRangePicker.css */

.date-picker-input {
    max-width: 150px;
    /* Ajuste conforme necessário */
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/DateRangePicker/DateRangePicker.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,gBAAgB;IAChB,+BAA+B;IAC/B,WAAW;AACf","sourcesContent":["/* DateRangePicker.css */\r\n\r\n.date-picker-input {\r\n    max-width: 150px;\r\n    /* Ajuste conforme necessário */\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
